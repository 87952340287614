import React from "react";
import axios from "axios";
import {
  BASE_URL,
  LOGS,
  HEADERS,
  CURRENTUSER,
  REGIONS,
  ADMIN,
  DRIVERS,
  PAYMENT,
  WAREHOUSE,
} from "../../common/api";
import "../../assets/order.css";
import { Form } from "react-bootstrap";
import Pagination from "../../common/pagination";
import Select from "react-select";

const orderStatus = [
  { value: 0, label: "Ochiq" },
  { value: 1, label: "Qabul qilindi" },
  { value: 2, label: "Jarayonda" },
  { value: 3, label: "Yolda" },
  { value: 4, label: "Yetkazildi" },
  { value: 5, label: "Bekor qilindi" },
  { value: 6, label: "Qaytarildi" },
];

const deliveryStatus = [
  { value: "Waiting", label: "Kutilmoqda" },
  { value: "Delivery attached", label: "Deliver biriktirildi" },
  { value: "Delivery accepted", label: "Deliver qabul qilgan" },
  { value: "Client accepted", label: "Mijoz qabul qilgan" },
  { value: "Client rejected", label: "Mijoz rad etgan" },
];

const order_item_status = [
  { value: 0, label: "Не подтверждён", backgroundColor: "bg-dark", color: "text-light" },
  { value: 1, label: "B производстве", backgroundColor: "bg-warning", color: "text-light" },
  { value: 2, label: "На складе", backgroundColor: "bg-primary", color: "text-light" },
  { value: 3, label: "В обработке", backgroundColor: "bg-light", color: "text-dark" },
  { value: 4, label: "Доставлено", backgroundColor: "bg-success", color: "text-light" },
  { value: 5, label: "Отменён", backgroundColor: "bg-danger", color: "text-light" },
  { value: 6, label: "В пути", backgroundColor: "bg-info", color: "text-light" },
  { value: 7, label: "В магазине", backgroundColor: "bg-secondary", color: "text-light" },

        
]

const paymentStatus = [
  { value: 0, label: "Umuman to'lanmagan" },
  { value: 1, label: "Termianl orqali to'langan" },
  { value: 2, label: "Deliver orqali to'langan" },
];

export default class Order extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      items: [],
      filters: {},
      currentPage: 1,
      totalPages: 1,
      employee: "",
    };
    this.overallPrice = 0;
    this._isMounted = false;
    this.today = new Date();

  }

  currencyFormat = (num) => {
    return num.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
  };

  edit = (id) => {
    this.props.history.push(`/single-order/${id}/1`);
  };

  handleFilter = (event, stateVariable, selectType = 0) => {
    const updateFilters = () => {
      const { filters } = this.state;
      const queryString = Object.entries(filters)
        .reduce((acc, [key, value]) => {
          if (value !== "") {
            // Check for empty strings
            return [...acc, `${key}=${value}`];
          }
          // If the value is an empty string, skip this filter
          return acc;
        }, [])
        .join("&");
      this.props.history.push(`?${queryString}`);
      // Remove the filters that have an empty string value
      const filteredFilters = Object.fromEntries(
        Object.entries(filters).filter(([key, value]) => value !== "")
      );
      this.setState({ filters: filteredFilters });
    };

    if (selectType === 2) {
      const value = event ? event.value : "";
      this.setState(
        {
          filters: {
            ...this.state.filters,
            [stateVariable]: value,
          },
        },
        updateFilters
      );
    } else {
      this.setState(
        {
          filters: {
            ...this.state.filters,
            [stateVariable]: event.target.value,
          },
        },
        updateFilters
      );
    }
  };

  handlePageChange(pageNumber) {
    this.setState({
      currentPage: pageNumber,
    });
    this.getData(pageNumber);
  }

  getData = (pageNumber = 1) => {
    this._isMounted = true;
    this._isMounted &&
      axios
        .get(
          BASE_URL + `special-orders/?page=${pageNumber}`,
          { params: this.state.filters },
          HEADERS
        )
        .then((res) => {
          const orders = res.data.results;
          orders.map((order) => {
            order['order_items'].map((order_item) => {
            order_item['backgroundColor'] = order_item_status[order_item['status']]['backgroundColor']
            order_item['color'] = order_item_status[order_item['status']]['color']
              
            })
          })
          this.setState({
            orders: orders,
            totalPages: Math.ceil(res.data.count / 500),
          });
        });
  };

  getSelectedValues = (filterProp, stateProp) => {
    const { filters } = this.state;
    const selectedValues =
      filters[filterProp] && filters[filterProp].split(",").map(Number);
    const arr = [];
    selectedValues &&
      selectedValues.map((el) => {
        const exist = this.state[stateProp].find((col) => col.value == el);
        return arr.push(exist);
      });
    return arr.length ? arr : [];
  };

  async componentDidMount() {
    this._isMounted = true;
    await axios.get(BASE_URL + CURRENTUSER, HEADERS).then((res) => {
      const regions = res.data.regions;
      const employee = res.data.id;
      const filterRegions = regions.join(",");
      this.setState({
        employee: employee,
        filters: {
          ...this.state.filters,
          region: filterRegions,
        },
      });
    });
    this._isMounted &&
      axios.get(BASE_URL + REGIONS, HEADERS).then((res) => {
        const arr = [];
        const data = res.data;
        data.map((el) => {
          return arr.push({ value: el.id, label: el.nameUz });
        });
        this.setState({ regions: arr });
      });
    this._isMounted &&
      axios.get(BASE_URL + ADMIN, HEADERS).then((res) => {
        const arr = [];
        const data = res.data;
        data.map((el) => {
          return arr.push({
            value: el.id,
            label: `${el.first_name}  ${el.last_name}`,
          });
        });
        this.setState({ managers: arr });
      });
    this._isMounted &&
      axios.get(BASE_URL + DRIVERS, HEADERS).then((res) => {
        const arr = [];
        const data = res.data;
        data.map((el) => {
          return arr.push({
            value: el.id,
            label: `${el.first_name}  ${el.last_name}`,
          });
        });
        this.setState({ drivers: arr });
      });
    this._isMounted &&
      axios.get(BASE_URL + PAYMENT, HEADERS).then((res) => {
        const arr = [];
        const data = res.data;
        data.map((el) => {
          return arr.push({ value: el.id, label: el.title });
        });
        this.setState({ payments: arr });
      });
    this._isMounted &&
      axios.get(BASE_URL + WAREHOUSE, HEADERS).then((res) => {
        const arr = [];
        const data = res.data;
        data.map((el) => {
          return arr.push({ value: el.id, label: el.name_uz });
        });
        this.setState({ shops: arr });
      });

    const searchParams = new URLSearchParams(window.location.search);
    const filters = {};
    for (let [key, value] of searchParams.entries()) {
      if (value === "true" || value === "false") {
        filters[key] = value === "true";
      } else if (isNaN(Number(value))) {
        filters[key] = value;
      } else {
        filters[key] = value;
      }
    }
    this.setState({ filters }, () => this.getData());
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.search !== prevProps.location.search){
      this.getData();
    }
  }
  handleStatus = (event, item, count, index, id) => {
     const data = {}
     let orders = this.state.orders || {}
     let logData = {
      action: `${ orders[count]['order_items'][index]['product']} statusi <${order_item_status[event.target.value]['label']}> ga  o'zgartirildi`,
      manager: this.state.employee,
      order: id }
     data["status"] = event.target.value
    this._isMounted && axios.patch( BASE_URL +  'order-items/' + item, data, HEADERS ).then((res)=>{
      axios.post(BASE_URL + LOGS + id + "/", logData, HEADERS)
    }).catch((err) => {
      console.log(err)
    })
    orders[count]['order_items'][index]['status'] = event.target.value
    orders[count]['order_items'][index]['backgroundColor'] = order_item_status[event.target.value]['backgroundColor']
    orders[count]['order_items'][index]['color'] = order_item_status[event.target.value]['color']
    this.setState({
      orders,
    })
  }
  

  componentWillUnmount() {
    this._isMounted = false;
  }

 

  render() {
    const {
      orders,
      filters,
      currentPage,
      totalPages,
      regions,
      managers,
      drivers,
      payments,
      shops,
    } = this.state;

    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isHovered ? 'red' : 'green', // Цвет при наведении
        color: state.isFocused ? 'black' : 'inherit', // Цвет текста при наведении
        cursor: 'pointer', // Изменение курсора при наведении
      }),
    };

    return (
      <  div style={{position: "relative"}}>
        <div className="filters">
          <div className="row">
            <div className="col" style={{ zIndex: "2" }}>
              <Form.Label>Buyurtma statusi:</Form.Label>
              <Select
                options={orderStatus}
                
                isClearable={true}
                placeholder="Tanlang..."
                onChange={(event) =>
                  this.handleFilter(event, "order_status", 2)
                }
                value={orderStatus.find(
                  (el) => el.value == filters.order_status
                )}
              />
            </div>
            <div className="col" style={{ zIndex: "3" }}>
              <Form.Label>To'lov statusi:</Form.Label>
              <Select
                options={paymentStatus}
                isClearable={true}
                placeholder="Tanlang..."
                onChange={(event) =>
                  this.handleFilter(event, "payment_status", 2)
                }
                value={paymentStatus.find(
                  (el) => el.value == filters.payment_status
                )}
              />
            </div>
            <div className="col" style={{ zIndex: "3" }}>
              <Form.Label>Yetkazma statusi:</Form.Label>
              <Select
                options={deliveryStatus}
                isClearable={true}
                placeholder="Tanlang..."
                onChange={(event) =>
                  this.handleFilter(event, "delivery_status", 2)
                }
                value={deliveryStatus.find(
                  (el) => el.value == filters.delivery_status
                )}
              />
            </div>
            <div className="col" style={{ zIndex: "2" }}>
              <Form.Label>Hudud:</Form.Label>
              <Select
                options={regions}
                isClearable={true}
                placeholder="Tanlang..."
                onChange={(event) => this.handleFilter(event, "region", 2)}
                value={
                  regions &&
                  regions.find((el) => el.value == filters.region)
                }
              />
            </div>
            <div className="col" style={{ zIndex: "2" }}>
              <Form.Label>Xodim:</Form.Label>
              <Select
                options={managers}
                isClearable={true}
                placeholder="Tanlang..."
                onChange={(event) => this.handleFilter(event, "manager", 2)}
                value={
                  managers &&
                  managers.find((el) => el.value == this.state.filters.manager)
                }
              />
            </div>
          </div>
          <div className="row pt-2">
            <div className="col">
              <Form.Label>Kuryer:</Form.Label>
              <Select
                options={drivers}
                isClearable={true}
                placeholder="Tanlang..."
                onChange={(event) => this.handleFilter(event, "deliver", 2)}
                defaultValue={
                  drivers &&
                  drivers.find((el) => el.value == this.state.filters.deliver)
                }
              />
            </div>
            <div className="col" style={{ zIndex: "2" }}>
              <Form.Label>To'lov turi:</Form.Label>
              <Select
                options={payments}
                isClearable={true}
                placeholder="Tanlang..."
                onChange={(event) => this.handleFilter(event, "payment_met", 2)}
                value={
                  payments &&
                  payments.find(
                    (el) => el.value == filters.payment_met
                  )
                }
              />
            </div>
            <div className="col" style={{ zIndex: "2" }}>
              <Form.Label>Magazin:</Form.Label>
              <Select
                options={shops}
                isClearable={true}
                placeholder="Tanlang..."
                onChange={(event) => this.handleFilter(event, "warehouse", 2)}
                value={
                  shops &&
                  shops.find((el) => el.value == filters.warehouse)
                }
              />
            </div>
            <div className="col">
              <Form.Label>Narx (dan):</Form.Label>
              <Form.Control
                type="number"
                placeholder="Kiriting"
                name="name"
                onChange={(event) => this.handleFilter(event, "price_min")}
                value={filters.price_min || ""}
              />
            </div>
            <div className="col">
              <Form.Label>Narx (gacha):</Form.Label>
              <Form.Control
                type="number"
                placeholder="Kiriting"
                name="name"
                onChange={(event) => this.handleFilter(event, "price_max")}
                value={filters.price_max || ""}
              />
            </div>
          </div>
          <div className="row pt-2">
            <div className="col">
              <Form.Label>Sana (dan):</Form.Label>
              <Form.Control
                type="date"
                placeholder="Kiriting"
                name="date_min"
                onChange={(event) => this.handleFilter(event, "date_min")}
                value={filters.date_min || ""}
              />
            </div>
            <div className="col">
              <Form.Label>Sana (gacha):</Form.Label>
              <Form.Control
                type="date"
                placeholder="Kiriting"
                name="date_max"
                onChange={(event) => this.handleFilter(event, "date_max")}
                value={filters.date_max || ""}
              />
            </div>
            <div className="col">
              <Form.Label>Ismi:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Kiriting"
                name="name"
                onChange={(event) => this.handleFilter(event, "name")}
                value={filters.name || ""}
              />
            </div>
            <div className="col">
              <Form.Label>ID:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Kiriting"
                name="id"
                onChange={(event) => this.handleFilter(event, "id")}
                defaultValue={filters.id || ""}
              />
            </div>
            <div className="col">
              <Form.Label>Telefon:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Kiriting"
                name="phone"
                onChange={(event) => this.handleFilter(event, "phone")}
                defaultValue={filters.phone || ""}
              />
            </div>
          </div>
        </div>
        {/* <div className="orders">
          {orders &&
            orders.map((item) => (
              <div
                className="order-card"
                key={item.id}
                onClick={() => this.edit(item.id)}
              >
                <div className="customer-name">
                  {item.id}. {item.name}
                </div>
                <div className="customer-info">
                  <div className="customer-address">
                    <span className="address">
                      <i className="fa-solid fa-location-dot"></i>{" "}
                      {item.region_name}
                    </span>
                    <span className="date">
                      {new Date(item.expected_delivery_date).toLocaleDateString(
                        "ru-RU"
                      )}
                    </span>
                  </div>
                  <div className="customer-phone">{`+${item.phone}`}</div>
                </div>
                <div className="main-info">
                  <div>
                    Buyurtma narxi:{" "}
                    <span className="sub-info">
                      {this.currencyFormat(String(item.price))} so'm
                    </span>
                  </div>
                  <div>
                    Chegirma: <span className="sub-info">0 so'm</span>
                  </div>
                  <div>
                    Naqd summa:{" "}
                    <span className="sub-info">
                      {this.currencyFormat(String(item.price))} so'm
                    </span>
                  </div>
                  <div>
                    Tizim orqali: <span className="sub-info">0 so'm</span>
                  </div>
                  <div>
                    Yetkazuvchi orqali: <span className="sub-info">0 so'm</span>
                  </div>
                </div>
                <span
                  className={`badge order-status ${
                    item.order_status !== 5 ? "badge-success" : "badge-danger"
                  }`}
                >
                  {item.status}
                </span>
              </div>
            ))}
        </div> */}
        <div  style={{ maxWidth: '100vw'}}>
          <table className="table">
            <thead style={{position: 'sticky', top: '0',  backgroundColor: "#f7f7f7", zIndex: '1'}}  >
              <tr >
                <th>ID</th>
                <th>Sotuvchi</th>
                <th>Mijoz</th>
                <th>Telefon</th>
                <th className="text-center">Mahsulot</th>
                <th>Magazin</th>
                <th>Yaratildi</th>
                <th>Kun qoldi</th>
                <th>Narxi</th>
                <th>Statusi</th>
              </tr>
            </thead>
            <tbody>
              {orders &&
                orders.map((item, count) => (
                  <tr
                  
                    key={item.id}
                    style={{cursor: 'pointer' , backgroundColor: item.expected_delivery_date   ?
                    Math.floor((new Date(item.expected_delivery_date).getTime() -  this.today.getTime())/(1000 * 3600 * 24)) + 1 <= 0 ? '#f8d7da' : 
                    Math.floor((new Date(item.expected_delivery_date).getTime() -  this.today.getTime())/(1000 * 3600 * 24)) + 1 == 4 ? '#c5e35b' :
                    Math.floor((new Date(item.expected_delivery_date).getTime() -  this.today.getTime())/(1000 * 3600 * 24)) + 1 <= 3 > 0 ? '#eef07a' : 
                    '' : ""
                  }}
                  >
                    <td onClick={() => this.edit(item.id)  }>{item.id}   </td>
                    <td onClick={() => this.edit(item.id)  } > {item.saler_firstname && item.saler_firstname + " " + item.saler_lastname}   </td>
                    <td onClick={() => this.edit(item.id)  }>{item.name}  </td>
                    <td  onClick={() => this.edit(item.id)  } >{`${item.phone}`}</td>
                    <td style={{minWidth: '700px' }}>
                      <ol>
                        {item.order_items.map((el, index) => (
                          <li key={index} className="row">
                            {" "}
                            <strong  onClick={() => this.edit(item.id)  } className="col-5">  {el.product}</strong>
                            <p className="mr-2">{el.quantity} {(el._type === '2' && el.image === null) ? "sm" : (el._type === '0' || el._type ==='1' ) ? "ta" : "m^2" }</p>
                            <p onClick={() => this.edit(item.id) } className="mr-1 col-2 align-middle ">{el.width}x{(el._type === '2' || el._type === '5') ? el.quantity : el.length} </p>
                            <select className={`mb-3 rounded  ${el.backgroundColor} ${el.color} `}  value={el.status} onChange={(event) => this.handleStatus(event, el.id, count, index, item.id)}>
                              {order_item_status.map((el, index) => (
                                <option key={index} className="text-dark bg-light" value={el.value}>{el.label}</option>
                              ))}
                            </select>
                            <p className="mx-1"  onClick={() => this.edit(item.id)  }>{el.SAP_status}</p>
                          </li>
                        ))}
                      </ol>
                    </td>
                    {/* <td  onClick={() => this.edit(item.id)  } >{item.region_name}</td> */}
                    <td onClick={() => this.edit(item.id)  } >{item.warehouse_name ? item.warehouse_name : ''}</td>
                    <td  onClick={() => this.edit(item.id)  } >{ new Date(item.created_at).toLocaleDateString("ru-RU") }</td>
                    <td onClick={() => this.edit(item.id)}>
                      {
                        item.expected_delivery_date ? 
                        Math.floor((new Date(item.expected_delivery_date).getTime() -  this.today.getTime())/(1000 * 3600 * 24)) + 1 : ""
                      }
                    </td>
                    <td  onClick={() => this.edit(item.id)  } >{this.currencyFormat(String(item.price))} so'm</td>
                    <td  onClick={() => this.edit(item.id)  } >
                    <span  className={`badge ${
                      item.order_status !== 5 ? "badge-success" : "badge-danger"
                    }`}>
                      {item.status}
                   

                    </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={(num) => this.handlePageChange(num)}
        />
      </div>
    );
  }
}
